var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import NumberRange from "~/components/common/number-range.vue";
import FollowAllQueryBatch from "~/components/case-manage/case-adjustment/follow-all-query-batch.vue";
import AppendWorkOrder from "~/components/case-manage/case-adjustment/append-work-order.vue";
import UpdateBaseCase from "~/components/case-manage/case-all-manage/update-base-case.vue";
import AddNote from "~/components/case-adjustment/add-note.vue";
import AddWarning from "~/components/case-adjustment/add-warning.vue";
import RefreshRetirementTime from "~/components/case-adjustment/refresh-retirement-time.vue";
import WarningDetails from "~/components/case-adjustment/warning-details.vue";
import CaseTypeForm from "~/components/case-info/case-type-form.vue";
import BatchAllot from "~/components/case-info/batch-allot.vue";
import { CaseInfoService } from "~/services/business-service/case-info.service";
import ManualAllot from "~/components/case-info/manual-allot.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { StorageService } from "~/utils/storage.service";
import { Layout, Dependencies } from "~/core/decorator";
import { PublicCollectionCaseService } from "~/services/domain-service/public-collection-case.service";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { StrategyDistributeService } from "~/services/dataimp-service/strategy-distribute.service";
import { Getter, namespace } from "vuex-class";
import { StatusConfigService } from "~/services/manage-service/status-config.service";
var collectionManageModule = namespace("collection-manage");
var CollectingCasePool = /** @class */ (function (_super) {
    __extends(CollectingCasePool, _super);
    function CollectingCasePool() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.caseRow = {};
        _this.loading = {
            state: false,
        };
        _this.waoptions = [{
                value: "",
                label: "行业调解",
            }, {
                value: "集中诉讼",
                label: "集中诉讼",
            }, {
                value: "分散诉讼",
                label: "分散诉讼",
            }];
        _this.collect = [];
        _this.contact = [];
        _this.searchModel = {
            principalId: "",
            batchNumber: "",
            personalName: "",
            certificateNo: "",
            caseNumber: "",
            cardNo: "",
            phone: "",
            stage: "",
            retireCaseDays: "",
            caseType: "",
            employerName: "",
            address: "",
            caseNumbers: "",
            certificateNos: "",
            phones: "",
            collectStatus: "",
            contactStatus: "",
            overdueAmtTotal: {
                min: "",
                max: "",
            },
            leftAmt: {
                min: "",
                max: "",
            },
            payStatus: "",
            delegationDate: [],
            comment: "",
            collectorName: "",
            departCode: "",
            overduePeriods: {
                min: "",
                max: "",
            },
            overdueDays: {
                min: "",
                max: "",
            },
            city: "",
            followTime: [],
            leaveFlag: "",
            endCaseDate: [],
            deptIds: [],
        };
        _this.rules = {
            certificateNo: [{ min: 6, message: "请输入至少6位的身份证号", trigger: "blur" }],
            phone: [{ min: 4, message: "请输入至少4位的手机号", trigger: "blur" }],
            cardNo: [{ min: 6, message: "请输入至少6位的卡号", trigger: "blur" }],
        };
        _this.dataSet = [];
        _this.dialog = {
            workerOrder: false,
            regionManualDivision: false,
            detail: false,
            updateCase: false,
            addnote: false,
            addwaring: false,
            waringDetails: false,
            retirement: false,
            retirecase: false,
            batchDivision: false,
            caseChange: false,
        };
        _this.currentDate = {};
        _this.showPhone = true;
        _this.currentCase = {};
        _this.selectionList = [];
        _this.caseId = "";
        return _this;
    }
    Object.defineProperty(CollectingCasePool.prototype, "payStatusEnum", {
        get: function () {
            var payEnum = this.$dict.getDictData("PayStatus").map(function (x) {
                return x;
            });
            return payEnum.filter(function (x) { return x.code !== "SETTLT"; });
        },
        enumerable: false,
        configurable: true
    });
    CollectingCasePool.prototype.mounted = function () {
        var userinfo = StorageService.getItem("userInfoStage");
        if ("tj001" == userinfo.username) {
            this.showPhone = false;
        }
        this.refreshData();
    };
    CollectingCasePool.prototype.removeEmptyProperties = function (obj) {
        return Object.fromEntries(Object.entries(obj)
            .filter(function (_a) {
            var _ = _a[0], value = _a[1];
            return value !== null &&
                value !== undefined &&
                value !== "" &&
                (Array.isArray(value) ? value.length > 0 : true) &&
                (typeof value === "object" && !Array.isArray(value) ? Object.keys(value).length > 0 : true);
        }));
    };
    CollectingCasePool.prototype.getStatusConfig = function () {
        var _this = this;
        if (this.searchModel.principalId) {
            this.statusConfigService.getStatusConfig(this.searchModel.principalId, this.loading).subscribe(function (data) {
                _this.contact = data.contactStatus;
                _this.collect = data.collectStatus;
            });
        }
    };
    CollectingCasePool.prototype.refreshData = function () {
        var _this = this;
        // this.loading.state = true;
        console.log(1, this.searchModel);
        this.caseInfoService.queryCollectingCases(this.removeEmptyProperties(this.searchModel), this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    // 策略分案
    CollectingCasePool.prototype.onStrategicSplitCase = function () {
        var _this = this;
        this.$confirm("确定执行策略分案?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.loading.state = true;
            _this.strategyDistributeService.manualExecuteStrategy("COLLECTING", _this.loading).subscribe(function (data) {
                _this.$message({
                    type: "success",
                    message: "正在进行策略分案，请稍后刷新页面!",
                });
            });
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消策略分案",
            });
        });
    };
    /**
     * 机构改变
     */
    CollectingCasePool.prototype.orgChange = function (valueArray) {
        if (this.searchModel.deptIds.length < 1) {
            return;
        }
        this.searchModel.departCode = valueArray[valueArray.length - 1];
    };
    CollectingCasePool.prototype.addWorkOrder = function (scope) {
        this.currentDate = scope.row;
        this.dialog.workerOrder = true;
    };
    CollectingCasePool.prototype.addNoteMethod = function (scope) {
        this.currentCase = scope.row;
        this.dialog.addnote = true;
    };
    CollectingCasePool.prototype.addWaring = function (scope) {
        this.currentCase = scope.row;
        this.dialog.addwaring = true;
    };
    CollectingCasePool.prototype.refreshTime = function (scope) {
        this.caseId = scope.row.id;
        this.dialog.retirement = true;
    };
    CollectingCasePool.prototype.retirementTime = function () {
        this.caseId = "";
        this.dialog.retirement = true;
    };
    /**
     * 查看警告详情
     */
    CollectingCasePool.prototype.waringDetails = function (scope) {
        this.currentCase = scope.row;
        this.dialog.waringDetails = true;
    };
    CollectingCasePool.prototype.handleResetForm = function () {
        this.searchModel.principalId = "";
        this.searchModel.overdueAmtTotal = {};
        this.searchModel.payStatus = "";
        this.searchModel.leftAmt = {};
        this.searchModel.departCode = "";
        this.searchModel.deptIds = [];
        this.searchModel.overduePeriods = {};
        this.searchModel.overdueDays = {};
    };
    CollectingCasePool.prototype.changeCaseType = function () {
        if (this.selectionList.length === 0) {
            this.$message.warning("请选择要更改调解方式的案件");
            return;
        }
        var caseType = "";
        var flag = false;
        this.selectionList.map(function (e) {
            if (caseType === "") {
                caseType = e.caseType;
            }
            else {
                if (caseType !== e.caseType) {
                    flag = true;
                    return;
                }
                else {
                    caseType = e.caseType;
                }
            }
        });
        if (flag) {
            this.$message.warning("所选案件的调解方式不统一");
            return;
        }
        else {
            this.dialog.caseChange = true;
        }
    };
    CollectingCasePool.prototype.stopCase = function () {
        var _this = this;
        if (!this.selectionList.length) {
            this.$message("请选择要停调的案件！");
            return;
        }
        var idList = this.selectionList.map(function (v) { return v.id; });
        this.$confirm("确定停调已选择的案件吗？", "提示", { type: "warning" }).then(function () {
            _this.caseInfoService.stopCases(idList).subscribe(function (data) {
                _this.$message.success("操作成功！");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    CollectingCasePool.prototype.returnCase = function () {
        var _this = this;
        if (!this.selectionList.length) {
            this.$message("请选择要退案的案件！");
            return;
        }
        var personalIds = this.selectionList.map(function (v) { return v.personalId; });
        var caseIds = this.selectionList.map(function (v) { return v.id; });
        this.$confirm("确定已选择的案件要进行退案吗？", "提示", { type: "warning" }).then(function () {
            _this.caseInfoService.changeRetireCases(personalIds, caseIds).subscribe(function (data) {
                _this.$message.success(" 退案操作成功！");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    CollectingCasePool.prototype.onManualSeparate = function () {
        if (this.selectionList.length === 0) {
            this.$message.info("请选择要分配的案件");
            return;
        }
        this.dialog.regionManualDivision = true;
        // this.$nextTick(() => {
        //   let form: any = this.$refs["manual-case-allot"] as any;
        //   form.refreshUserData();
        // });
    };
    CollectingCasePool.prototype.onBatchDivision = function () {
        this.dialog.batchDivision = true;
        // this.$nextTick(() => {
        //   let form: any = this.$refs["case-batch-allot"] as any;
        //   form.refreshUserData();
        // });
    };
    CollectingCasePool.prototype.openRecord = function (scope) {
        var otherData = {
            sort: this.sortService.clone(),
            caseType: scope.row.caseType,
        };
        this.updateOtherData(otherData);
        this.currentCase = scope.row;
        this.dialog.detail = true;
    };
    __decorate([
        Dependencies(PageService)
    ], CollectingCasePool.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CollectingCasePool.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PublicCollectionCaseService)
    ], CollectingCasePool.prototype, "publicCollectionCaseService", void 0);
    __decorate([
        Dependencies(StrategyDistributeService)
    ], CollectingCasePool.prototype, "strategyDistributeService", void 0);
    __decorate([
        Dependencies(StatusConfigService)
    ], CollectingCasePool.prototype, "statusConfigService", void 0);
    __decorate([
        Getter
    ], CollectingCasePool.prototype, "departmentData", void 0);
    __decorate([
        Getter
    ], CollectingCasePool.prototype, "orgTreeData", void 0);
    __decorate([
        Dependencies(CaseInfoService)
    ], CollectingCasePool.prototype, "caseInfoService", void 0);
    __decorate([
        Dependencies(StorageService)
    ], CollectingCasePool.prototype, "storageService", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectingCasePool.prototype, "updateOtherData", void 0);
    CollectingCasePool = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                AddNote: AddNote,
                AddWarning: AddWarning,
                PrincipalBatch: PrincipalBatch,
                AppendWorkOrder: AppendWorkOrder,
                RefreshRetirementTime: RefreshRetirementTime,
                FollowAllQueryBatch: FollowAllQueryBatch,
                UpdateBaseCase: UpdateBaseCase,
                BatchAllot: BatchAllot,
                ManualAllot: ManualAllot,
                WarningDetails: WarningDetails,
                CaseTypeForm: CaseTypeForm,
            },
        })
    ], CollectingCasePool);
    return CollectingCasePool;
}(Vue));
export default CollectingCasePool;
